export const companyServices = [
  {
    value: 'permanent_hires',
    label: 'Permanent/direct hires',
    subItems: [
      {value: 'permanent_contingency_search', label: 'Contingency search'},
      {value: 'permanent_retained_search', label: 'Retained search'},
      {value: 'permanent_executive_search', label: 'Executive search'},
    ],
  },
  {
    value: 'temporary_hires',
    label: 'Contingent/temporary hires',
    subItems: [
      {value: 'temp_w_2_employment', label: 'W-2 employment'},
      {value: 'temp_corp_to_corp', label: 'Corp-to-corp'},
    ],
  },
  {
    value: 'temporary_to_permanent_hires',
    label: 'Temporary to permanent hires',
  },
  {
    value: 'recruitment_process_outsourcing',
    label: 'Recruitment Process Outsourcing',
  },
  {value: 'other_hires', label: 'Other'},
];

export const COMPANY_SERVICE_VALUES = {
  permanent_hires: 'Permanent/direct hires',
  permanent_retained_search: 'Retained search',
  permanent_contingency_search: 'Contingency search',
  permanent_executive_search: 'Executive search',
  temporary_hires: 'Contingent/temporary hires',
  temp_w_2_employment: 'W-2 employment',
  temp_corp_to_corp: 'Corp-to-corp',
  temporary_to_permanent_hires: 'Temporary to permanent hires',
  recruitment_process_outsourcing: 'Recruitment Process Outsourcing',
  other_hires: 'Other',
};
