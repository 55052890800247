import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import {COUNTRIES, US_STATES} from 'app/constants';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize';
import {getPhoneNumber} from 'app/utils/helpers/DataFormatHelper';
import PropTypes from 'prop-types';
import React from 'react';

const BillingAddressView = ({details, isClient}) => {
  return (
    <AppCard style={{maxWidth: 820}}>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Billing Contact</Typography.Title>
        <Typography.Paragraph>{details?.contact}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Billing Email Address</Typography.Title>
        <Typography.Paragraph>{details?.email_address}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Billing Phone Number</Typography.Title>
        <Typography.Paragraph>
          {getPhoneNumber(details?.phone_number)}
        </Typography.Paragraph>
      </div>
      {isClient && (
        <div className={'mb-5'}>
          <Typography.Title level={4}>Invoicing Period</Typography.Title>
          <Typography.Paragraph>
            {capitalizeFirstLetter(details?.invoicing_period)}
          </Typography.Paragraph>
        </div>
      )}
      <div className={'mb-5'}>
        <Typography.Title level={4}>Country</Typography.Title>
        <Typography.Paragraph>
          {COUNTRIES[details?.country]}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Address Line 1</Typography.Title>
        <Typography.Paragraph>{details?.line_1}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Address Line 2</Typography.Title>
        <Typography.Paragraph>{details?.line_2}</Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>City</Typography.Title>
        <Typography.Paragraph>{details?.city}</Typography.Paragraph>
      </div>
      {details?.country === 'united_states' && details?.state && (
        <div className={'mb-5'}>
          <Typography.Title level={4}>State / Province</Typography.Title>
          <Typography.Paragraph>
            {US_STATES[details?.state]}
          </Typography.Paragraph>
        </div>
      )}

      <div className={'mb-5'}>
        <Typography.Title level={4}>Postal / Zip Code</Typography.Title>
        <Typography.Paragraph>{details?.postal_code}</Typography.Paragraph>
      </div>
    </AppCard>
  );
};

BillingAddressView.propTypes = {
  details: PropTypes.object,
  isClient: PropTypes.bool,
};

export default BillingAddressView;
