import {companyInfoSchema} from '@register-app/schemas/company-info.schema';
import {diversityInfoSchema} from '@register-app/schemas/diversity-info.schema';
import {getDefRegion} from '@register-app/schemas/service-region.schema';
import {array, object, string} from 'yup';

import {supplierTypes as SUPPLIER_TYPES} from '../../app/constants/supplier-types';

const getArgs = (supplierType) => {
  return {
    isBoth:
      (supplierType?.includes(SUPPLIER_TYPES.recruitingAgency) ||
        supplierType?.includes(SUPPLIER_TYPES.staffingAgency)) &&
      supplierType?.includes(SUPPLIER_TYPES.talentVendor),
    isService:
      supplierType?.length === 1 &&
      supplierType?.includes(SUPPLIER_TYPES.talentVendor),
    isJob: !supplierType?.includes(SUPPLIER_TYPES.talentVendor),
  };
};
export const supplierUpdateSchema = object({
  status: string().required('Status is Required'),
  has_insurance: string(),
  service_region_countries: array()
    .of(string())
    .min(1, 'Service regions required')
    .required('Service regions required'),
  service_region_us_states: array().of(string()),
  specialization_job_functions: object().when('type', {
    is: (value) => {
      const {isJob, isBoth} = getArgs(value);
      return isJob || isBoth;
    },
    then: () => getDefRegion('job function').required(),
    otherwise: () => object().nullable(),
  }),
  specialization_service_focuses: object().when('type', {
    is: (value) => {
      const {isService, isBoth} = getArgs(value);
      return isService || isBoth;
    },
    then: () => getDefRegion('service').required(),
    otherwise: () => object().nullable(),
  }),
  specialization_industry_focuses: getDefRegion('industry').required(),
})
  .concat(diversityInfoSchema)
  .concat(companyInfoSchema);
