import useStep from 'app/hooks/useStep';
import JobInformation from 'app/shared/requisitions/RequisitionSteps/JobDescription/JobInformation';
import SelectionForm from 'app/shared/requisitions/RequisitionSteps/JobDescription/SelectionForm';
import PropTypes from 'prop-types';
import React from 'react';

import {useUser} from '../../../../store/features/user/userSlice';
import {
  getFullName,
  getPhoneNumber,
} from '../../../../utils/helpers/DataFormatHelper';

const JobDescription = ({requisitionModal}) => {
  const {nextStep, setStepData, stepData, currentStep} = useStep();
  const {user} = useUser();

  const currentRecruiter = {
    id: user?.id ?? null,
    name: user?.name ? user.name : getFullName(user, false),
    email: user?.email,
    first_name: user?.first_name,
    last_name: user?.last_name,
    title: user?.title,
    phoneNumber: getPhoneNumber(user?.phone_number),
  };

  const addName = (obj) => ({...obj, name: getFullName(obj, false)});

  return (
    <React.Fragment>
      {currentStep > 1 && stepData ? (
        <JobInformation requisitionModal={requisitionModal} />
      ) : (
        currentStep === 1 && (
          <SelectionForm
            initialValues={{
              job_title: stepData?.job_title,
              openings: stepData?.openings,
              type: stepData?.type,
              priority: stepData?.priority,
              job_status: stepData?.job_status,
              part_time: stepData?.part_time,
              hiring_manager: stepData?.hiring_manager?.id,
              hiringManager: stepData?.hiring_manager,
              recruiter: stepData?.recruiter?.id || currentRecruiter.id,
              recruiterOption: stepData?.recruiter
                ? addName(stepData?.recruiter)
                : currentRecruiter,
              accept_candidates_from: stepData?.accept_candidates_from,
            }}
            onSave={setStepData}
            onContinue={nextStep}
          />
        )
      )}
    </React.Fragment>
  );
};

JobDescription.propTypes = {
  requisitionModal: PropTypes.object,
};

export default React.memo(JobDescription);
