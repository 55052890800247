import {companyTypesNames} from '@register-app/constants/company-type';
import {DATE_FORMATS} from 'app/config/app';

import {SERVICE_FOCUSES, SUPPLIER_RELATIONSHIP_STATUSES} from '../constants';
import CompanyModal from './CompanyModal';

class SupplierModal extends CompanyModal {
  constructor(supplier) {
    super(supplier);
    Object.assign(this, supplier);
    this.serviceFocus = supplier?.service_focus
      ? SERVICE_FOCUSES[supplier?.service_focus]
      : 'N/A';
    this.relationStatus = supplier?.relation_status
      ? SUPPLIER_RELATIONSHIP_STATUSES[supplier?.relation_status]
      : null;
    this.locationInquiriesPreference = this.getCountries(
      supplier?.location_inquiries_preference,
    );
    this.browsingVisible = this.getProfileVisibility(
      supplier?.browsing_visible,
    );
    this.receiveInquiry = this.getReceiveInquiry(supplier?.receive_inquiry);
    this.joinedAt = supplier?.created_at
      ? 'Joined ' + this.formatDate(supplier?.created_at, DATE_FORMATS.sm)
      : 'N/A';
    this.activeClients = supplier?.active_clients ?? 0;
    this.completedEngagements = supplier?.completed_engagements ?? 0;
    this.availableResources = supplier?.available_resources ?? 0;
    this.has_insurance = supplier?.has_insurance || false;
    this.company_dba_name = supplier?.company_dba_name ?? supplier?.name;
    this.years_in_business = supplier?.years_in_business || 0;
    this.company_type = supplier?.company_type;
    this.companyType = this?.company_type
      ? companyTypesNames[this?.company_type]
      : 'N/A';
  }

  getProfileVisibility(visibility) {
    if (visibility === false && this.locationProfileVisibility.length > 0) {
      return 'Visible to some clients';
    } else if (
      visibility === false &&
      this.locationProfileVisibility.length === 0
    ) {
      return 'Not visible';
    } else {
      return 'Visible to all clients';
    }
  }

  getReceiveInquiry(allowed) {
    if (allowed === false && this.locationInquiriesPreference.length > 0) {
      return 'Allowed from some clients';
    } else if (
      allowed === false &&
      this.locationInquiriesPreference.length === 0
    ) {
      return 'Not allowed';
    } else {
      return 'Allowed from all clients';
    }
  }
}

export default SupplierModal;
