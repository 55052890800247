import {Form, Radio} from 'antd';
import React, {useEffect} from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import {specificGroupOptions} from '../../constants/company-diversity-statuses';
import AppSelect from '../controles/select';

const DiversitySourcingPractices = () => {
  const {control, watch, setValue} = useFormContext();
  const selectedOption = watch('diversity_sourcing_practices.value');

  useEffect(() => {
    if (selectedOption !== 'specific_group') {
      setValue('diversity_sourcing_practices.description', undefined);
    }
  }, [selectedOption, setValue]);

  return (
    <Controller
      name='diversity_sourcing_practices.value'
      control={control}
      render={({field, fieldState: {error}}) => (
        <Form.Item
          labelCol={{span: 24}}
          wrapperCol={{span: 24}}
          required
          layout={'vertical'}
          label='Diversity Sourcing Practices'
          status={error ? 'error' : ''}
          help={error ? error.message : ''}>
          <Radio.Group {...field} style={{display: 'block'}}>
            <div className='flex-col flex gap-4'>
              <Radio value='specific_group'>
                {`This company's stated mission or focus is the placement of
                  specific underserved group(s)`}
              </Radio>
              {selectedOption === 'specific_group' && (
                <AppSelect
                  name='diversity_sourcing_practices.description'
                  placeholder='Select Group'
                  allowClear
                  mode='multiple'
                  options={specificGroupOptions}
                  style={{
                    maxWidth: 280,
                  }}
                />
              )}
              <Radio value='diverse_pools'>
                {`This company's stated mission or focus is the creation of highly
                  diverse candidate pools`}
              </Radio>

              <Radio value='training_methods'>
                {`This company's specific training or methods ensure that
                  diversity candidates are not disadvantaged`}
              </Radio>

              <Radio value='not_a_focus'>
                Diversity sourcing is not a particular focus
              </Radio>
            </div>
          </Radio.Group>
        </Form.Item>
      )}
    />
  );
};

export default DiversitySourcingPractices;
