import {getRequiredMessage} from '@wieldy/utils/helpers/MessageHelper';
import {object, string} from 'yup';

import {PHONE} from '../utils/regx';

export const billingAddressSchema = object({
  contact: string().required(getRequiredMessage('Contact')),
  email_address: string()
    .email('Enter correct email address')
    .required(getRequiredMessage('Email')),
  phone_number: string().matches(PHONE, 'Enter correct phone'),
  country: string().required(getRequiredMessage('Country')),
  line_1: string().required(getRequiredMessage('Address')),
  line_2: string(),
  city: string().required(getRequiredMessage('City')),
  state: string().when('country', {
    is: (val) => val === 'united_states',
    then: (schema) => schema.required(getRequiredMessage('State')),
    otherwise: (schema) => schema.notRequired(),
  }),
  postal_code: string().required(getRequiredMessage('Postal / Zip Code')),
});
