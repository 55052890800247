import SupplierHelper from 'app/utils/SupplierHelper';

export default {
  status: {
    label: 'Engagement Status',
    options: SupplierHelper.getEngagementStatus(),
  },
  filter_status: {
    label: 'Engagement Status',
    options: SupplierHelper.getFilterEngagementStatus(),
  },
  company_size: {
    label: 'Company Size',
    options: SupplierHelper.getEmployeeSize(),
  },
  specialization_service_focuses: {
    label: 'Service Focuses',
    options: SupplierHelper.serviceFocuses(),
    hasIndex: true,
  },
  specialization_industry_focuses: {
    label: 'Industry Focuses',
    options: SupplierHelper.getIndustries(),
    hasIndex: true,
  },
  specialization_job_functions: {
    label: 'Job Functions',
    options: SupplierHelper.getJobFunctions(),
    hasIndex: true,
  },
  relation_status: {
    label: 'Supplier Relationship Status',
    options: SupplierHelper.getRelationshipStatus(),
  },
  diversity_statuses: {
    label: 'Diversity Statuses',
    options: SupplierHelper.getDiversityStatus(),
  },
  active_clients: {
    label: ' # of Active Clients',
    options: SupplierHelper.getActiveClients(),
    hasQuery: true,
  },
  created_at: {
    label: 'Membership Duration',
    options: SupplierHelper.getMembershipDuration(),
    hasQuery: true,
  },
  country: {
    label: 'Based In',
    options: SupplierHelper.getCountries(),
  },
  completed_engagements: {
    label: 'Completed Engagements',
    options: SupplierHelper.completedEngagements(),
    hasQuery: true,
  },
  rating_total: {
    label: 'Performance Rating',
    options: SupplierHelper.getPerformanceRating(),
    hasQuery: true,
  },
  available_resources: {
    label: '# of Available Resources',
    options: SupplierHelper.availableResouces(),
    hasQuery: true,
  },
  deployed_resources: {
    label: '# of Deployed Resources',
    options: SupplierHelper.getDeployedResouces(),
    hasQuery: true,
  },
};
