import {yupResolver} from '@hookform/resolvers/yup';
import CompanyPlacement from '@register-app/components/additional/company-placement';
import ParentChildCheckbox from '@register-app/components/additional/multiple-checkbox';
import CompanyMemberController from '@register-app/components/controles/company-member-contoller';
import Editor from '@register-app/components/controles/editor';
import AppFormInput from '@register-app/components/controles/input';
import AppFormSelect from '@register-app/components/controles/select';
import DiversityInformationFields from '@register-app/components/form-fields/diversity-information-fields';
import ServiceRegionsFields from '@register-app/components/form-fields/service-regions-fields';
import {companyServices} from '@register-app/constants/company-services';
import {companyTypesOptions} from '@register-app/constants/company-type';
import {isSubjectOptionsForEdit} from '@register-app/constants/is-subject-options';
import {platformsOptions} from '@register-app/constants/platforms';
import {supplierUpdateSchema} from '@register-app/schemas/supplier-update.schema';
import {getRecruitingServices} from '@register-app/utils/get-recruiting-services';
import {getRegionArgs} from '@register-app/utils/get-region-args';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import {
  getInputPlaceHolder,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Col, Form, notification, Row, Space, Typography} from 'antd';
import {accountStatusesOptions} from 'app/constants/account-statuses';
import {
  supplierTypes as SUPPLIER_TYPES,
  supplierTypesOptions,
} from 'app/constants/supplier-types';
import {useAccountType} from 'app/hooks/useAccountType';
import {useRole} from 'app/hooks/useRole';
import {useUploadImageToAws} from 'app/services/globalService';
import AvatarUpload from 'app/shared/components/AvatarUpload';
import ClientHelper from 'app/utils/ClientHelper';
import SupplierHelper from 'app/utils/SupplierHelper';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {RiBuildingLine} from 'react-icons/ri';

import {InsuranceSupplierState} from '../../../../../requisitions/ViewRequisition/constatns';

const EditSupplierCompanyDetail = ({
  onCancel,
  onSubmit,
  initialValues = {},
  loading = false,
}) => {
  const {isMarketplace} = useAccountType();
  const [disabled, setDisabled] = React.useState(false);
  const [uploadLogo, setUploadLogo] = React.useState();
  const [companyLogo, setCompanyLogo] = React.useState(initialValues?.logo);
  const uploadMutation = useUploadImageToAws();
  const {isMarketManager, isAdmin} = useRole();

  const defaultValues = useMemo(
    () => ({
      ...(initialValues ? initialValues : {}),
      company_representative: initialValues?.company_representative?.id,
      has_insurance: initialValues?.has_insurance ? 'yes' : 'no',
      company_recruiting_services: getRecruitingServices(initialValues),
      diversity_statuses: initialValues?.diversity_statuses?.reduce(
        (acc, value) => {
          const {key, ...obj} = value;
          acc[key] = {
            check: true,
            values: {
              ...obj,
              dates_of_certification: obj.dates_of_certification.map((el) =>
                moment(el),
              ),
            },
          };
          return acc;
        },
        {},
      ),
      placement_type: {
        placement_temporary_hires: initialValues?.placement_temporary_hires,
        placement_temp_to_perm: initialValues?.placement_temp_to_perm,
        placement_retained_fee: initialValues?.placement_retained_fee,
        placement_contingent_placement_fee:
          initialValues?.placement_contingent_placement_fee,
      },
    }),
    [initialValues],
  );

  const methods = useForm({
    resolver: yupResolver(supplierUpdateSchema),
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    watch,
    formState: {errors},
    setValue,
  } = methods;

  console.log(errors, '----error');

  const supplierTypes = watch('type');
  const schemaArgs = getRegionArgs(supplierTypes);

  const isAgency =
    supplierTypes.includes(SUPPLIER_TYPES.recruitingAgency) ||
    supplierTypes.includes(SUPPLIER_TYPES.staffingAgency);

  const isSomeChecked =
    !!watch('diversity_statuses') &&
    Object.values(watch('diversity_statuses')).some((el) => el.check);

  useEffect(() => {
    if (initialValues?.diversity_statuses) {
      setValue('confirm_info', true);
    }
  }, [initialValues.diversity_statuses, setValue]);

  React.useEffect(() => {
    if (uploadLogo) {
      setDisabled(false);
    }
  }, [uploadLogo]);

  const handleOnFinish = (formdata) => {
    // eslint-disable-next-line no-unused-vars
    const {placement_type, company_recruiting_services, country, ...data} =
      formdata;
    const {
      placement_contingent_placement_fee,
      placement_retained_fee,
      placement_temporary_hires,
      placement_temp_to_perm,
    } = placement_type;

    const payloadData = {
      ...data,
      has_insurance: InsuranceSupplierState.yes === data.has_insurance,
      account_manager: data?.account_manager?.id,
      ...(isAgency ? company_recruiting_services : getRecruitingServices()),
      diversity_statuses: Object.entries(data?.diversity_statuses)
        .filter(([, value]) => value.check)
        .map(([key, value]) => ({...value.values, key})),
      placement_contingent_placement_fee: isAgency
        ? placement_contingent_placement_fee
        : null,
      placement_retained_fee: isAgency ? placement_retained_fee : null,
      placement_temporary_hires: isAgency ? placement_temporary_hires : null,
      placement_temp_to_perm: isAgency ? placement_temp_to_perm : null,
      tax_jurisdiction: data.tax_jurisdiction,
      ats_used_by_company: isAgency ? data.ats_used_by_company : null,
      number_of_recruiters: isAgency ? data.number_of_recruiters : null,
      specialization_job_functions:
        schemaArgs.isJob || schemaArgs.isBoth
          ? data.specialization_job_functions
          : null,
      specialization_service_focuses:
        schemaArgs.isService || schemaArgs.isBoth
          ? data.specialization_service_focuses
          : null,
    };

    if (uploadLogo) {
      uploadMutation.mutate(
        {
          uploadUrl: uploadLogo.upload_url,
          file: uploadLogo.file,
        },
        {
          onSuccess: () => {
            const payload = {
              ...payloadData,
              logo: uploadLogo.url,
            };
            onSubmit(payload);
          },
          onError: () => {
            notification.error({
              message:
                'Timeout to upload a logo expired, please try again to upload file',
            });
            setUploadLogo(undefined);
            setCompanyLogo(initialValues?.logo);
          },
        },
      );
    } else {
      onSubmit(payloadData);
    }
  };

  return (
    <AppFormWrapper>
      <FormProvider {...methods}>
        <Form
          id='supplier-registration'
          layout={'vertical'}
          onFinish={handleSubmit(handleOnFinish)}
          onFieldsChange={() => setDisabled(false)}
          colon={false}>
          <AppFormBody>
            <Row gutter={[10, 5]}>
              <Col md={8}>
                <AppFormInput
                  isRequired
                  name={'name'}
                  label={'Company Name'}
                  placeholder={getInputPlaceHolder('company name')}
                />
              </Col>
              <Col md={8}>
                <AppFormInput
                  name={'company_dba_name'}
                  label={'DBA Company Name'}
                  placeholder={getInputPlaceHolder('DBA company name')}
                />
              </Col>
              {isMarketplace && initialValues.type && (
                <Col md={8}>
                  <AppFormSelect
                    name='type'
                    label='Supplier Type'
                    isRequired
                    placeholder={getSelectPlaceHolder('supplier types')}
                    options={supplierTypesOptions}
                    allowClear
                    style={{width: '100%'}}
                    mode='multiple'
                  />
                </Col>
              )}
              <Col md={8}>
                <AppFormSelect
                  name={'company_type'}
                  label={'Company Type'}
                  isRequired
                  placeholder={getSelectPlaceHolder('company type')}
                  options={companyTypesOptions}
                />
              </Col>
              <Col md={8}>
                <AppFormSelect
                  name={'company_size'}
                  label={'Company Size'}
                  isRequired
                  placeholder={getSelectPlaceHolder('company size')}
                  options={ClientHelper.getEmployeeSize()}
                />
              </Col>
              <Col md={8}>
                <AppFormSelect
                  isRequired
                  name={'status'}
                  label={'Engagement Status'}
                  placeholder={getSelectPlaceHolder('status')}
                  options={SupplierHelper.getEngagementStatus().filter(
                    (statusOption) => statusOption.value !== 'closed',
                  )}
                />
              </Col>
              <Col md={8}>
                <AppFormInput
                  isRequired
                  name={'website'}
                  label={'Website'}
                  placeholder={getInputPlaceHolder('website')}
                />
              </Col>
              <Col md={8}>
                <AppFormInput
                  isRequired
                  name={'linkedin'}
                  label={'LinkedIn Page'}
                  placeholder={getInputPlaceHolder('LinkedIn page')}
                />
              </Col>
              <Col md={8}>
                <AppFormInput
                  isRequired
                  type='number'
                  name={'years_in_business'}
                  label={'Years in Business'}
                  style={{width: '100%'}}
                  min={0}
                  placeholder={getInputPlaceHolder('years of business')}
                />
              </Col>
              <Col md={8}>
                <AppFormSelect
                  isRequired
                  name={'tax_jurisdiction'}
                  label={'Company Tax Jurisdiction'}
                  placeholder={getSelectPlaceHolder('tax jurisdiction')}
                  options={isSubjectOptionsForEdit}
                />
              </Col>
              <Col md={8}>
                <CompanyMemberController
                  name={'company_representative'}
                  label={'Company Representative'}
                  variant={'supplier'}
                  placeholder={getSelectPlaceHolder('company representative')}
                  filterMembers={(m) =>
                    m.role !== 'resource' && m.role !== 'hiring_manager'
                  }
                  initialFetch={true}
                  showArrow={false}
                  allowClear={true}
                  defaultValue={initialValues?.company_representative?.name}
                />
              </Col>
              {(isAdmin || isMarketManager) && (
                <Col md={8}>
                  <AppFormSelect
                    name={'has_insurance'}
                    label={'Has Insurance'}
                    options={SupplierHelper.getInsuranceValue()}
                    placeholder={getSelectPlaceHolder('has insurance')}
                  />
                </Col>
              )}
            </Row>
            <Row gutter={[10, 5]}>
              {isAgency && (
                <>
                  <Col md={8}>
                    <Form.Item
                      label='Recruiting/Staffing Services'
                      required
                      validateStatus={
                        errors?.company_recruiting_services ? 'error' : ''
                      }
                      help={
                        errors?.company_recruiting_services?.root
                          ? errors.company_recruiting_services.root.message
                          : ''
                      }>
                      <div className='d-flex flex-column'>
                        {companyServices.map((item) => (
                          <ParentChildCheckbox
                            name='company_recruiting_services'
                            key={item.value}
                            item={item}
                          />
                        ))}
                      </div>
                    </Form.Item>
                  </Col>
                  <Col md={16}>
                    <CompanyPlacement isRequired={true} />
                  </Col>
                  <Col md={8}>
                    <AppFormInput
                      isRequired
                      type='number'
                      style={{width: '100%'}}
                      min={0}
                      name={'number_of_recruiters'}
                      label={'# of Recruiters'}
                      placeholder={getInputPlaceHolder('number of recruiters')}
                    />
                  </Col>
                  <Col md={8}>
                    <AppFormSelect
                      name='ats_used_by_company'
                      label='ATS Used by Company'
                      showSearch
                      placeholder={getSelectPlaceHolder('ATS')}
                      style={{width: '100%'}}
                      options={platformsOptions}
                    />
                  </Col>
                </>
              )}
              {isMarketplace && (
                <Col md={8}>
                  <AppFormSelect
                    name='account_status'
                    label='Account Status'
                    showSearch
                    placeholder={getSelectPlaceHolder('account status')}
                    style={{width: '100%'}}
                    options={accountStatusesOptions}
                  />
                </Col>
              )}
            </Row>
            <DiversityInformationFields
              isSomeChecked={isSomeChecked}
              isHint={false}
            />
            <ServiceRegionsFields schemaArgs={schemaArgs} isAdminPage />

            <Row>
              <Col md={24}>
                <Editor
                  isRequired
                  name={'about'}
                  label={'About'}
                  readOnly={false}
                  isCompanyProfile
                />
              </Col>
              <Col sm={24}>
                <Typography.Title level={4}>Company Logo</Typography.Title>
                <AvatarUpload
                  variant='supplier'
                  id={initialValues?.id}
                  url={companyLogo}
                  onChange={setUploadLogo}
                  icon={<RiBuildingLine fontSize={40} />}
                />
              </Col>
            </Row>
          </AppFormBody>
          <AppFormActions>
            <Space size={16} className={'mt-7'}>
              <Button
                type='primary'
                htmlType='submit'
                loading={uploadMutation.isLoading || loading}
                disabled={disabled}>
                Save
              </Button>

              <Button type='primary' onClick={onCancel} ghost>
                Cancel
              </Button>
            </Space>
          </AppFormActions>
        </Form>
      </FormProvider>
    </AppFormWrapper>
  );
};

EditSupplierCompanyDetail.propTypes = {
  onCancel: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};
export default EditSupplierCompanyDetail;
