import {DIVERSITY_ENTITY} from '@register-app/constants/company-diversity-statuses';
import {JOB_FUNCTIONS} from '@register-app/constants/job-functions';
import {SERVICE_FOCUS} from '@register-app/constants/service-focus';
import {
  FILTER_SUPPLIER_ENGAGEMENT_STATUSES,
  SUPPLIER_ENGAGEMENT_STATUSES,
  SUPPLIER_RELATIONSHIP_STATUSES,
  SUPPLIER_USER_TYPES,
} from 'app/constants';

import {supplierTypes} from '../constants/supplier-types';
import {InsuranceSupplierState} from '../pages/main/requisitions/ViewRequisition/constatns';
import CompanyHelper from './CompanyHelper';

class SupplierHelper extends CompanyHelper {
  static serviceFocuses() {
    return Object.keys(SERVICE_FOCUS).map((key, index) => {
      return {
        key: key,
        value: key,
        label: SERVICE_FOCUS[key].value,
        query: {
          [`${key}[${index}]`]: 1,
        },
      };
    });
  }

  static getJobFunctions() {
    return Object.keys(JOB_FUNCTIONS).map((key) => {
      return {
        key: key,
        value: key,
        label: JOB_FUNCTIONS[key].value,
      };
    });
  }

  static getEngagementStatus() {
    return Object.keys(SUPPLIER_ENGAGEMENT_STATUSES).map((key) => {
      return {
        key: key,
        value: key,
        label: SUPPLIER_ENGAGEMENT_STATUSES[key],
      };
    });
  }

  static getFilterEngagementStatus() {
    return Object.keys(FILTER_SUPPLIER_ENGAGEMENT_STATUSES).map((key) => {
      return {
        key: key,
        value: key,
        label: FILTER_SUPPLIER_ENGAGEMENT_STATUSES[key],
      };
    });
  }

  static getDiversityStatus() {
    return Object.keys(DIVERSITY_ENTITY).map((key) => {
      return {
        key: key,
        value: key,
        label: DIVERSITY_ENTITY[key],
      };
    });
  }
  static getInsuranceValue() {
    return Object.keys(InsuranceSupplierState).map((key) => {
      return {
        key: key,
        value: key,
        label: InsuranceSupplierState[key],
      };
    });
  }
  static getUserTypes() {
    return Object.keys(SUPPLIER_USER_TYPES).map((key) => {
      return {
        key: key,
        label: SUPPLIER_USER_TYPES[key],
        value: key,
      };
    });
  }

  static hasAccessByType = (sourceType, accessType, exact = false) => {
    const types = accessType || [
      supplierTypes.staffingAgency,
      supplierTypes.recruitingAgency,
    ];

    if (exact) {
      return types.every((item) => sourceType.includes(item));
    }

    return types.reduce((acc, item) => {
      if (sourceType.includes(item)) {
        acc = true;
      }
      return acc;
    }, false);
  };

  static getRelationshipStatus() {
    return Object.keys(SUPPLIER_RELATIONSHIP_STATUSES).map((key) => {
      return {
        key: key,
        label: SUPPLIER_RELATIONSHIP_STATUSES[key],
        value: key,
      };
    });
  }

  static getActiveClients(name = 'active_clients') {
    return [
      {
        key: '1-5',
        value: '1-5',
        label: '1-5 clients',
        query: {
          [`${name}[gte]`]: 1,
          [`${name}[lte]`]: 5,
        },
      },
      {
        key: '6-10',
        value: '6-10',
        label: '6-10 clients',
        query: {
          [`${name}[gte]`]: 6,
          [`${name}[lte]`]: 10,
        },
      },
      {
        key: '11-20',
        value: '11-20',
        label: '11-20 clients',
        query: {
          [`${name}[gte]`]: 11,
          [`${name}[lte]`]: 20,
        },
      },
      {
        key: '21-50',
        value: '21-50',
        label: '21-50 clients',
        query: {
          [`${name}[gte]`]: 21,
          [`${name}[lte]`]: 50,
        },
      },
      {
        key: '50+',
        value: '50+',
        label: '50+ clients',
        query: {
          [`${name}[gt]`]: 50,
        },
      },
    ];
  }

  static availableResouces(key = 'available_resources') {
    return [
      {
        key: '1-10',
        value: '1-10',
        label: '1-10 resources',
        query: {
          [`${key}[gte]`]: 1,
          [`${key}[lte]`]: 10,
        },
      },
      {
        key: '11-20',
        value: '11-20',
        label: '11-20 resources',
        query: {
          [`${key}[gte]`]: 11,
          [`${key}[lte]`]: 20,
        },
      },
      {
        key: '21-50',
        value: '21-50',
        label: '21-50 resources',
        query: {
          [`${key}[gte]`]: 21,
          [`${key}[lte]`]: 50,
        },
      },
      {
        key: '51-100',
        value: '51-100',
        label: '51-100 resources',
        query: {
          [`${key}[gte]`]: 51,
          [`${key}[lte]`]: 100,
        },
      },
      {
        key: '100+',
        value: '100+',
        label: '100+ resources',
        query: {
          [`${key}[gt]`]: 100,
        },
      },
    ];
  }
}

export default SupplierHelper;
