import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {getSelectPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Col, Form} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import formFields from './config';

const FilterSuppliers = ({showRelationshipStatus = true}) => {
  return (
    <React.Fragment>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label={formFields.filter_status.label} name='status'>
          <AppSelect
            allowClear
            placeholder={getSelectPlaceHolder('status')}
            options={formFields.filter_status.options}
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label={formFields.company_size.label} name='company_size'>
          <AppSelect
            placeholder={getSelectPlaceHolder('company size(s)')}
            allowClear
            options={formFields.company_size.options}
            mode='multiple'
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          label={formFields.specialization_job_functions.label}
          name='specialization_job_functions'>
          <AppSelect
            mode='multiple'
            placeholder={getSelectPlaceHolder('service focus')}
            options={formFields.specialization_job_functions.options}
            allowSearch
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          label={formFields.specialization_service_focuses.label}
          name='specialization_service_focuses'>
          <AppSelect
            mode='multiple'
            placeholder={getSelectPlaceHolder('service focus')}
            options={formFields.specialization_service_focuses.options}
            allowSearch
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          label={formFields.specialization_industry_focuses.label}
          name='specialization_industry_focuses'>
          <AppSelect
            placeholder={getSelectPlaceHolder('industry focus')}
            mode='multiple'
            options={formFields.specialization_industry_focuses.options}
            allowSearch
          />
        </Form.Item>
      </Col>

      {showRelationshipStatus && (
        <Col xs={24} sm={12} md={8} lg={6}>
          <Form.Item
            label={formFields.relation_status.label}
            name='relation_status'>
            <AppSelect
              allowClear
              placeholder={getSelectPlaceHolder('status')}
              options={formFields.relation_status.options}
              allowSearch
            />
          </Form.Item>
        </Col>
      )}

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          label={formFields.diversity_statuses.label}
          name='diversity_statuses'>
          <AppSelect
            placeholder={getSelectPlaceHolder('diversity status')}
            options={formFields.diversity_statuses.options}
            allowClear
            mode='multiple'
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          label={formFields.active_clients.label}
          name='active_clients'>
          <AppSelect
            placeholder={getSelectPlaceHolder('range(s)')}
            options={formFields.active_clients.options}
            allowClear
            mode='multiple'
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label={formFields.created_at.label} name='created_at'>
          <AppSelect
            placeholder={getSelectPlaceHolder('duration')}
            options={formFields.created_at.options}
            allowClear
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label={formFields.country.label} name='country'>
          <AppSelect
            mode='multiple'
            placeholder={getSelectPlaceHolder('countries')}
            options={formFields.country.options}
            allowSearch
            allowClear
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          label={formFields.completed_engagements.label}
          name='completed_engagements'>
          <AppSelect
            placeholder={getSelectPlaceHolder('range(s)')}
            options={formFields.completed_engagements.options}
            allowClear
            mode='multiple'
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label={formFields.rating_total.label} name='rating_total'>
          <AppSelect
            placeholder={getSelectPlaceHolder('rating')}
            options={formFields.rating_total.options}
            allowClear
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          label={formFields.available_resources.label}
          name='available_resources'>
          <AppSelect
            placeholder={getSelectPlaceHolder('range(s)')}
            options={formFields.available_resources.options}
            allowClear
            mode='multiple'
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item
          label={formFields.deployed_resources.label}
          name='deployed_resources'>
          <AppSelect
            placeholder={getSelectPlaceHolder('range(s)')}
            options={formFields.deployed_resources.options}
            allowClear
            mode='multiple'
          />
        </Form.Item>
      </Col>
    </React.Fragment>
  );
};

FilterSuppliers.propTypes = {
  showRelationshipStatus: PropTypes.bool,
};

export default React.memo(FilterSuppliers);
