export const getRecruitingServices = (initialValues) => ({
  permanent_hires: initialValues?.permanent_hires || null,
  permanent_retained_search: initialValues?.permanent_retained_search || null,
  permanent_contingency_search:
    initialValues?.permanent_contingency_search || null,
  permanent_executive_search: initialValues?.permanent_executive_search || null,
  temporary_hires: initialValues?.temporary_hires || null,
  temp_w_2_employment: initialValues?.temp_w_2_employment || null,
  temp_corp_to_corp: initialValues?.temp_corp_to_corp || null,
  temporary_to_permanent_hires:
    initialValues?.temporary_to_permanent_hires || null,
  recruitment_process_outsourcing:
    initialValues?.recruitment_process_outsourcing || null,
  other_hires: initialValues?.other_hires || null,
});
