import AppCard from '@wieldy/components/AppCard';
import {Descriptions} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {ACCEPT_CANDIDATES_FROM_MAP, WORK_TYPES} from '../../../../constants';
import MultipleValuePreview from '../../../components/MultipleValuePreview';

const JobInformation = ({requisitionModal}) => {
  return (
    <AppCard style={{maxWidth: 680}}>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Job Title'>
          {requisitionModal.jobTitle}
        </Descriptions.Item>
        <Descriptions.Item label='Type'>
          {WORK_TYPES[requisitionModal.type]}
        </Descriptions.Item>
        <Descriptions.Item label='Accept Candidates From'>
          <MultipleValuePreview
            type={requisitionModal.accept_candidates_from}
            source={ACCEPT_CANDIDATES_FROM_MAP[requisitionModal.type]}
          />
        </Descriptions.Item>
        <Descriptions.Item label='Priority'>
          {requisitionModal.jobPriority}
        </Descriptions.Item>
        <Descriptions.Item label='Openings'>
          {requisitionModal.openings}
        </Descriptions.Item>
        <Descriptions.Item label='Part Time'>
          {requisitionModal.partTime}
        </Descriptions.Item>
        <Descriptions.Item label='Recruiter'>
          {requisitionModal.recruiter.name}
        </Descriptions.Item>
        <Descriptions.Item>{}</Descriptions.Item>
        {requisitionModal.hiringManager && (
          <>
            <Descriptions.Item label='Hiring Manager'>
              {requisitionModal.hiringManager?.name}
            </Descriptions.Item>
            <Descriptions.Item>{}</Descriptions.Item>
          </>
        )}
      </Descriptions>
    </AppCard>
  );
};

JobInformation.propTypes = {
  requisitionModal: PropTypes.object,
};

export default JobInformation;
